<template>
  <div>
    <v-skeleton-loader
      v-if="isDropped"
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
    <v-card
      v-if="!isDropped"
      :draggable="isDraggable"
      class="mt-3 d-flex flex-column"
      :style="urgencyCard(documentObj.priority)"
      height="100%"
      @contextmenu.prevent="showContextualMenu"
      @dragstart="onDragStart"
    >
      <v-card-title>
        <h5>{{ documentObj.title }}</h5>

        <v-spacer></v-spacer>

        <group-documents-menu
          v-if="isOwner"
          :show-menu="isShow.contextualMenu"
          @closeMenu="closeContextualMenu"
          @clickEdit="isShow.edit = true"
          @clickDelete="isShow.delete = true"
        />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <div>
              {{ documentObj.description }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-spacer />
      <v-card-actions>
        <div
          v-if="documentObj.flag_auto_import === 'yes'"
        >
          <v-tooltip
            top
          >
            <template
              v-slot:activator="{ on, attrs }"
            >
              <v-chip
                v-bind="attrs"
                class="ma-2 white--text success"
                v-on="on"
              >
                Importazione automatica
              </v-chip>
            </template>
            Il documento verrà importato automaticamente in ogni nuova pratica
          </v-tooltip>
        </div>
        <v-spacer></v-spacer>

        <group-documents-action-menu
          is-plugin-version
          :document-obj="documentObj"
          :document-route="documentRoute"
          @clickUpload="isShow.upload = true"
        />
      </v-card-actions>
      <edit-group-document
        is-plugin-version
        no-auto-import
        :is-show="isShow.edit"
        :document-data="documentObj"
        @close="isShow.edit=false"
        @reload-data="reloadData"
      />
      <destroy-group-document
        is-plugin-version
        :is-show="isShow.delete"
        :document-data="documentObj"
        @close="isShow.delete=false"
        @reload-data="reloadData"
      />
      <upload-file
        :is-show="isShow.upload"
        :upload-url="uploadUrl"
        @close="isShow.upload=false"
        @reload-data="reloadData"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditGroupDocument from '@/components/modals/edit-group-document.vue'
import DestroyGroupDocument from '@/components/modals/delete-group-document.vue'
import UploadFile from '@/components/modals/upload-file.vue'
import GroupDocumentsMenu from '@/components/menus/group-documents-menu.vue'
import GroupDocumentsActionMenu from '@/components/menus/group-documents-action-menu.vue'

export default {
  name: 'GroupDocumentsCard',
  components: {
    GroupDocumentsActionMenu,
    GroupDocumentsMenu,
    EditGroupDocument,
    DestroyGroupDocument,
    UploadFile,
  },
  props: {
    documentObj: {
      type: Object,
      required: true,
    },
    documentRoute: {
      type: String,
      default: 'groupDocumentFiles',
    },
    isPluginVersion: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    isDropped: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: {
        upload: false,
        edit: false,
        delete: false,
        contextualMenu: false,
      },
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'loggedUserGroup']),
    uploadUrl() {
      let url = ''
      if (this.isPluginVersion) {
        url = `${this.$apiUrl}ext/group-documents/${this.loggedUserGroup.id}/documents/${this.documentObj.id}`
      } else {
        url = `${this.$apiUrl}group-documents/${this.loggedUserGroup.id}/documents/${this.documentObj.id}`
      }

      return url
    },
    isOwner() {
      return this.loggedUser.company_role === 'owner'
    },
  },
  methods: {
    onDragStart(ev) {
      // send the id of the element, so it can be moved into the folder
      ev.dataTransfer.setData('documentId', this.documentObj.id)
      ev.dataTransfer.setData('groupId', this.documentObj.group_id)
    },
    urgencyCard(priority) {
      if (priority === 'high') {
        return 'border-top:10px solid #ffccbc;'
      } if (priority === 'low') {
        return 'border-top:10px solid #c8e6c9;'
      }

      return 'border-top:10px solid #c5cae9;'
    },
    async reloadData() {
      await this.$emit('reload-data')
    },
    closeContextualMenu() {
      this.isShow.contextualMenu = false
    },
    showContextualMenu(e) {
      e.preventDefault()
      this.isShow.contextualMenu = true
    },
  },
}

</script>
