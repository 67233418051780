<template>
  <div
    class="d-flex justify-end"
  >
    <!-- Desktop -->
    <div
      class="hidden-xs-only"
    >
      <v-btn
        v-if="!noBackButton && backButtonRoute !== this.$router.currentRoute.path"
        class="my-3"
        :to="backButtonRoute"
      >
        {{ backButtonText }}
      </v-btn>
      <v-btn
        v-if="showReloadResource"
        :color="btnColor"
        :dark="btnDark"
        rounded
        class="my-3 mx-2"
        :loading="loadingReloadResource"
        @click="clickReloadResource"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-btn
        v-if="isOwner"
        :color="btnColor"
        :dark="btnDark"
        class="my-3 mx-2"
        @click="clickCreateFolder"
      >
        <v-icon>mdi-plus</v-icon>
        {{ dictBtnAddFolder }}
      </v-btn>
      <v-btn
        v-if="isOwner"
        :color="btnColor"
        :dark="btnDark"
        class="my-3 mx-2"
        @click="clickCreateDocument"
      >
        <v-icon>mdi-plus</v-icon>
        {{ dictBtnAddDocument }}
      </v-btn>
      <v-btn
        v-if="isOwner"
        :color="btnColor"
        :dark="btnDark"
        class="my-3 mx-2 hidden-xs-only"
        @click="clickCreateFile"
      >
        <v-icon>mdi-plus</v-icon>
        {{ dictBtnAddFile }}
      </v-btn>
    </div>
    <!-- Mobile -->
    <div
      class="my-3 mx-3 hidden-sm-and-up w-full"
    >
      <v-btn
        v-if="!noBackButton && backButtonRoute !== this.$router.currentRoute.path"
        block
        small
        rounded
        class="my-3 px-2"
        :to="backButtonRoute"
      >
        {{ backButtonText }}
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="isOwner"
        block
        small
        rounded
        class="my-3 px-2"
        :color="btnColor"
        :dark="btnDark"
        @click="clickCreateFolder"
      >
        <v-icon>mdi-plus</v-icon>
        {{ dictBtnAddFolder }}
      </v-btn>
      <v-btn
        v-if="isOwner"
        block
        small
        rounded
        class="my-3 px-2"
        :color="btnColor"
        :dark="btnDark"
        @click="clickCreateFile"
      >
        <v-icon>mdi-plus</v-icon>
        {{ dictBtnAddFile }}
      </v-btn>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'GroupDocumentsHeader',
  props: {
    showReloadResource: {
      type: Boolean,
      default: false,
    },
    loadingReloadResource: {
      type: Boolean,
      default: false,
    },
    backButtonText: {
      type: String,
      default: 'Torna allo spazio condiviso',
    },
    backButtonRoute: {
      type: String,
      default: '/plugin-group-documents',
    },
    noBackButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['partnerSettings', 'loggedUser']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
    dictBtnAddFolder() {
      return this.partnerSettings?.dictionary?.procedure?.folders?.add ?? 'Cartella'
    },
    dictBtnAddDocument() {
      return this.partnerSettings?.dictionary?.procedure?.documents?.add ?? 'Documento'
    },
    dictBtnAddFile() {
      return this.partnerSettings?.dictionary?.procedure?.files?.add ?? 'File'
    },
    isOwner() {
      return this.loggedUser.company_role === 'owner'
    },
  },
  methods: {
    clickCreateDocument() {
      this.$emit('clickCreateDocument')
    },
    clickCreateFolder() {
      this.$emit('clickCreateFolder')
    },
    clickCreateFile() {
      this.$emit('clickCreateFile')
    },
    clickReloadResource() {
      this.$emit('clickReloadResource')
    },
  },
}
</script>
