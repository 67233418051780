<template>
  <v-card
    class="pa-2"
    @contextmenu.prevent
  >
    <v-card-title
      class="
        subheading
        font-weight-bold
        info--text
        hidden-xs-only
      "
    >
      <span>{{ sectionName }} di {{ loggedUserGroup.name }}</span>
    </v-card-title>
    <v-card-title
      class="
        subheading
        font-weight-bold
        info--text
        hidden-sm-and-up
      "
    >
      <span>
        {{ sectionName }}
      </span>
    </v-card-title>
    <v-card-actions>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="9"
          class="d-flex align-center"
        >
          <v-row
            class="match-height"
          >
            <v-col
              sm="12"
              md="2"
              xs="6"
            >
              <generic-info-avatar
                :title="documentsCounterLabel"
                :total="groupDocumentsList.length"
                color="success"
                icon="mdi-file-document"
              />
            </v-col>
            <v-col
              sm="12"
              md="2"
              xs="6"
            >
              <generic-info-avatar
                :title="documentFilesCounterLabel"
                :total="totalDocumentFiles"
                color="info"
                icon="mdi-file"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import GenericInfoAvatar from '@/components/avatars/generic-info-avatar.vue'

export default {
  name: 'GroupCard',
  components: { GenericInfoAvatar },
  props: {
    sectionName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['loggedUserGroup', 'groupDocumentsList', 'groupFilesList', 'partnerSettings']),
    documentsCounterLabel() {
      return this.partnerSettings?.dictionary?.main?.[this.groupDocumentsList.length === 1 ? 'document' : 'documents'] ?? (this.groupDocumentsList.length === 1 ? 'Documento' : 'Documenti')
    },
    documentFilesCounterLabel() {
      return this.partnerSettings?.dictionary?.main?.[this.groupDocumentsList.files_count === 1 ? 'file' : 'files'] ?? (this.groupDocumentsList.files_count === 1 ? 'File' : 'Files')
    },
    totalDocumentFiles() {
      return this.groupDocumentsList.reduce((acc, doc) => acc + doc.files_count, 0) + this.groupFilesList.length
    },
  },
}

</script>
